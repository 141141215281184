import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
// styles
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './converthero.module.css';
// components
import Layout from '../components/item/layout';
//other
import { meta } from '../../../metaData';

function Converthero() {
  const data = useStaticQuery(graphql`
    query {
      converthero: file(relativePath: { eq: "сonverthero/converthero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1720, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img1: file(relativePath: { eq: "сonverthero/img1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2: file(relativePath: { eq: "сonverthero/img2.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3: file(relativePath: { eq: "сonverthero/img3.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img4: file(relativePath: { eq: "сonverthero/img4.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img5: file(relativePath: { eq: "сonverthero/img5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img6: file(relativePath: { eq: "сonverthero/img6.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img7: file(relativePath: { eq: "сonverthero/img7.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img8: file(relativePath: { eq: "сonverthero/img8.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img9: file(relativePath: { eq: "сonverthero/img9.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img10: file(relativePath: { eq: "сonverthero/img10.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img11: file(relativePath: { eq: "сonverthero/img11.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img12: file(relativePath: { eq: "сonverthero/img12.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img13: file(relativePath: { eq: "сonverthero/img13.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      img14: file(relativePath: { eq: "сonverthero/img14.png" }) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      score={['Martech', 'USA', '2019', 'Finished']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="ConvertHero&nbsp;— &#10;exit-intent popup tool"
      subTitle="Add high converting popups to your site in&nbsp;minutes without&nbsp;a&nbsp;developer"
      coverImgUrl={data.converthero.childImageSharp.fluid.srcWebp}
      metaData={meta.converthero}
    >
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Our client</h2>
        <p className={itemStyles.paragraph}>
          The creators of this service found out that up to 80% of web-site visitors will never
          return to the site they visited. The value of the service is that it counts the exact
          moment when the user is about to leave the web-site and offers them personalized services
          (discounts, free subscriptions, etc).
        </p>
      </div>
      <BackgroundImage
        fluid={data.img1.childImageSharp.fluid}
        className={styles.bg}
        style={{
          backgroundPosition: 'top left 35%',
        }}
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Key features</h2>
        <ul className={itemStyles.bullets}>
          <li>Choose from the templates created by professional designers.</li>
          <li>
            Customize every element of the pop-up, so it fully matches your site design and gels
            with you brand image.
          </li>
          <li>Choose the precise time when you want your pop-up to be shown.</li>
          <li>Get the detailed results of your campaigns.</li>
        </ul>
        <h2 className={itemStyles.title}>Prototype</h2>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          We started our work with the client from prototyping the idea. The tool we used for
          prototyping was UXPin.
        </p>

        <Img fluid={data.img2.childImageSharp.fluid} className={styles.img} alt="" />
        <Img fluid={data.img3.childImageSharp.fluid} className={styles.img} alt="" />
        <Img fluid={data.img4.childImageSharp.fluid} className={styles.img} alt="" />
      </div>
      <BackgroundImage
        fluid={data.img5.childImageSharp.fluid}
        className={styles.bgContent}
        style={{
          backgroundPosition: 'top left 38%',
        }}
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Case study</h2>
        <p className={clsx(itemStyles.paragraph, styles.paragraph)}>
          We used Ruby on Rails and React.js to develop the service. The user's workflow can be
          described in the following way:
        </p>

        <Img fluid={data.img6.childImageSharp.fluid} className={styles.img} alt="" />
        <p className={styles.imgCaption}>
          The authentication to the site is carried out through the user's Facebook, Twitter, Github
          or Google account.
        </p>
        <Img fluid={data.img7.childImageSharp.fluid} className={styles.img} alt="" />
        <p className={styles.imgCaption}>
          All you need is just to choose the template that suits your business the most.
        </p>
        <Img fluid={data.img8.childImageSharp.fluid} className={styles.img} alt="" />
        <p className={styles.imgCaption}>Customize your template.</p>

        <Img fluid={data.img9.childImageSharp.fluid} className={styles.img} alt="" />
        <p className={styles.imgCaption}>
          Keep on experimenting with the template's style: set the color scheme and image for the
          background, the animation, create some fields, etc.
        </p>

        <Img fluid={data.img10.childImageSharp.fluid} className={styles.img} alt="" />
        <p className={styles.imgCaption}>
          Enter your company name and assigned domain, specify your target set.
        </p>

        <Img fluid={data.img11.childImageSharp.fluid} className={styles.img} alt="" />
        <p className={styles.imgCaption}>Choose the CMS of your website and setup your pop-up.</p>

        <Img fluid={data.img12.childImageSharp.fluid} className={styles.img} alt="" />
        <p className={styles.imgCaption}>
          Monitor the statistics of the pop-up in the form of a diagram.
        </p>

        <Img fluid={data.img13.childImageSharp.fluid} className={styles.img} alt="" />
        <p className={styles.imgCaption}>
          Review the names of the users along with their e-mail addresses and the collection date.
        </p>

        <Img fluid={data.img14.childImageSharp.fluid} className={styles.img} alt="" />
        <p className={clsx(styles.imgCaption, styles.imgLastCaption)}>Bill the service.</p>
      </div>
    </Layout>
  );
}

export default Converthero;
